<template>
  <q-card class="q-pa-sm full-width shadow" :class="isMissing ? 'bg-red-6' : ''">
    <q-card-section class="border-bottom q-py-xs">
      <div class="text-h6 text-center">
        {{ item.name || '--' }} <span v-if="item.offerId">({{ item.offerId }})</span>
      </div>
    </q-card-section>

    <q-card-section
      v-if="isExpectedItem && showDifference"
      class="row q-py-sm border-bottom items-center justify-center"
    >
      <div class="bg-grey rounded q-pa-sm">
        <strong>
          {{ startCount }}
        </strong>
      </div>

      <div class="q-pa-sm">
        +
      </div>

      <div class="bg-warning rounded q-pa-sm">
        <strong>
          {{ Math.abs(diff) }}
        </strong>
      </div>

      <div class="q-pa-sm">
        =
      </div>

      <div class="bg-positive rounded q-pa-sm">
        <strong>
          {{ scannedCount + ' / ' + originalCount }}
        </strong>
      </div>
    </q-card-section>

    <q-card-section class="row q-py-xs">
      <img
        class="m-auto"
        style="height: 70px; width: 70px; object-fit: contain;"
        :src="item.image || fallbackImage"
        :alt="item.name"
        @error="onImageLoadFailure"
      >

      <div class="col q-pl-md">
        <div class="col-12 q-pt-xs">
          <div
            v-if="item.o_id"
            class="row items-center"
          >
            <div class="col-4">
              {{ $t('Order') }}
            </div>

            <div class="col-8 text-subtitle1">
              <strong>{{ item.o_id }}</strong>
            </div>
          </div>

          <div
            v-if="item.o_extId"
            class="row items-center"
          >
            <div class="col-4">
              {{ $t('Order ExtId') }}
            </div>

            <div class="col-8 text-subtitle1">
              <strong>{{ item.o_extId }}</strong>
            </div>
          </div>

          <div
            v-if="isMissing"
            class="row items-center"
          >
            <div class="col-4">
              {{ $t('Missing Products') }}
            </div>

            <div class="col-8 text-subtitle1">
              <strong>{{ missingItems }}</strong>
            </div>
          </div>

          <div
            v-if="isExpectedItem && !showDifference"
            class="row items-center"
          >
            <div class="col-4">
              {{ $t('Amount') }}
            </div>

            <div class="col-8">
              <strong>
                {{ item.quantity || item.count }}
              </strong>
            </div>
          </div>

          <div
            v-if="!removeAmount && !isExpectedItem"
            class="row items-center"
          >
            <div class="col-4">
              {{ $t('Amount') }}
            </div>

            <div class="col-8 text-subtitle1">
              <div
                v-if="showDifference && (originalItem.count > 0 || originalItem.quantity > 0) && countDifference !== 0"
                class="row items-center"
              >
                <div class="bg-gray rounded q-pa-sm">
                  <strong>
                    {{ originalItem.count || originalItem.quantity }}
                  </strong>
                </div>

                <div class="q-pa-sm">
                  <span v-if="originalItem.count < item.count || originalItem.quantity < item.quantity">
                    +
                  </span>

                  <span v-else>
                    -
                  </span>
                </div>

                <div class="bg-warning rounded q-pa-sm">
                  <strong>
                    {{ Math.abs(countDifference) }}
                  </strong>
                </div>

                <div class="q-pa-sm">
                  =
                </div>

                <div class="bg-success rounded q-pa-sm">
                  <strong>
                    {{ item.count || item.quantity }}
                  </strong>
                </div>
              </div>

              <strong v-else>
                {{ (item.quantity || item.count || 0) }}
              </strong>
            </div>
          </div>

          <div class="row items-center">
            <div class="col-4">
              {{ $t('Sku') }}
            </div>

            <div class="col-8 text-subtitle2">
              {{ item.sku }}
            </div>
          </div>

          <div
            v-if="!removeBarcode"
            class="row items-center"
          >
            <div class="col-4">
              {{ $t('Barcode') }}
            </div>

            <div class="col-8 text-subtitle2">
              {{ extractBarcodes(item.barcodes) }}
            </div>
          </div>

          <div
            v-if="item.article"
            class="row items-center"
          >
            <div class="col-4">
              {{ $t('Article') }}
            </div>

            <div class="col-8 text-subtitle2">
              {{ item.article }}
            </div>
          </div>
        </div>
      </div>
    </q-card-section>

    <q-card-section v-if="details" class="border-top q-mt-xs q-pt-xs">
      <div
        v-for="(item, i) in item.items"
        :key="i"
        class="row items-center q-mb-none"
      >
        <div class="col-5">
          <q-badge
            class="q-pa-xs"
            :color="itemStates[item.state]"
            :label="$t(stateName[item.state] || item.state)"
          />
        </div>

        <div class="col-7 text-subtitle1">
          <span>
            {{ item.count }}
          </span>

          <span v-if="item.order" class="text-subtitle2">
            ({{ $t('Order') + ': ' + getOrder(item.order) }})
          </span>
        </div>
      </div>
    </q-card-section>
  </q-card>
</template>

<script>
export default {
  name: 'OrderItem',
  props: {
    details: {
      type: Boolean,
      default () {
        return false
      }
    },
    item: {
      type: Object,
      default () {
        return null
      }
    },
    removeAmount: {
      type: Boolean,
      default () {
        return false
      }
    },
    removeBarcode: {
      type: Boolean,
      default () {
        return false
      }
    },
    showDifference: {
      type: Boolean,
      default () {
        return false
      }
    },
    isExpectedItem: {
      type: Boolean,
      default () {
        return false
      }
    },
    isMissing: {
      type: Boolean,
      default () {
        return false
      }
    }
  },
  data () {
    return {
      stateName: {
        normal: 'On hand',
        blocked: 'Blocked',
        booked: 'Booked',
        new: 'Received'
      },
      itemStates: {
        new: 'default',
        blocked: 'danger',
        booked: 'danger',
        normal: 'success',
        expected: 'green',
        shipped: 'green'
      },
      fallbackImage: 'assets/img/fallback-image/package.png',
      originalItem: {
        id: null,
        count: 0,
        expectedCount: 0
      },
      countDifference: 0,
      // Counts for expected item
      originalCount: 0,
      startCount: 0,
      scannedCount: 0,
      diff: 0
    }
  },
  watch: {
    item: {
      handler (value) {
        this.setCounts(value)
      },
      deep: true
    }
  },
  computed: {
    missingItems () {
      if (!this.isMissing) {
        return 0
      }

      return this.item.items.reduce((acc, i) => {
        return acc + (i.missingItems || 0)
      }, 0)
    }
  },
  mounted () {
    this.setCounts(this.item)
  },
  methods: {
    getOrder (order) {
      return order && typeof order === 'object'
        ? order.id
        : order
    },
    setCounts (value) {
      if (value && value.id !== this.originalItem.id) {
        this.originalItem = { ...value }
        this.countDifference = 0

        // Calculations for Expected Item
        this.originalCount = this.originalItem.count || this.originalItem.quantity
        this.startCount = this.originalCount - this.originalItem.expectedCount - 1

        this.scannedCount = this.originalCount - this.item.expectedCount
        this.diff = 1
      } else if (this.showDifference) {
        this.countDifference = this.getCountDifference(this.originalItem.count || this.originalItem.quantity, this.item.count || this.item.quantity)

        // Calculations for Expected Item
        this.scannedCount = (this.item.count || this.item.quantity) - this.item.expectedCount
        this.diff = this.scannedCount - this.startCount
      }
    },
    getCountDifference (originalCount = 0, newCount = 0) {
      if (isNaN(Number(originalCount))) {
        originalCount = 0
      }

      if (isNaN(Number(newCount))) {
        newCount = 0
      }

      return originalCount - newCount
    },
    extractBarcodes (barcode) {
      if (!barcode) {
        return barcode
      }

      if (barcode[0] === '[' && barcode[barcode.length - 1] === ']') {
        const barcodes = JSON.parse(barcode)
        return barcodes[barcodes.length - 1]
      }

      if (Array.isArray(barcode)) {
        return barcode[barcode.length - 1]
      }

      return barcode
    },
    onImageLoadFailure (e) {
      e.target.src = this.fallbackImage
    }
  }
}
</script>
